.angular-ui-tree {
  //background: red;
}
.angular-ui-tree-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
  pointer-events: none;
}
.angular-ui-tree-nodes {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 20px;
}
.angular-ui-tree-node, .angular-ui-tree-placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}
.angular-ui-tree-hidden {
  display: none;
}
.angular-ui-tree-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
}
.angular-ui-tree-handle {
  //cursor: move;
  text-decoration: none;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 20px;
  line-height: 20px;
}
.angular-ui-tree-drag {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: .8;
}
.angular-ui-tree-handle {
  background: #f8faff;
  border: 1px solid #dae2ea;
  color: #7c9eb2;
  padding: 10px 10px;
}
.angular-ui-tree-handle:hover {
  color: #438eb9;
  background: #f4f6f7;
  border-color: #dce2e8;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
tr.angular-ui-tree-empty {
  height: 100px
}
.group-title {
  background-color: #687074 !important;
  color: #fff !important;
}
/* --- Tree --- */
.tree-node {
  border: 1px solid #dae2ea;
  background: #f8faff;
  color: #7c9eb2;
}
.nodrop {
  background-color: #f2dede;
}
.tree-node-content {
  margin: 10px;
}
.tree-node-button {
  margin-left: 4px;
}
.tree-handle {
  padding: 10px;
  background: #428bca;
  color: #fff;
  margin-right: 10px;
}
.angular-ui-tree-handle:hover {
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}