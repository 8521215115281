/**
   Component: uiselect.less
*/
.ui-select-bootstrap > .ui-select-match {
  text-align: left;
}
.ui-select-toggle {
  font-size: 14px;
}
// 超出文字处理
.ui-select-match-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
}
.ui-select-clear {
  //margin-right: 10px;
  top: 9px;
  position: absolute;
  right: 24px;
}

