/**
   Component: print.less
*/

// 
// Put here all rules required to style the print view 
// 

@media print {
  
  .sidebar,
  .topnavbar,
  .settings,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  .app-container,
  .app-container > section,
  .app {
    margin: 0 !important; /* remove margin used for sidebar and expand the content */
    // padding: 0 !important;
    width: 100% !important;
  }

  .app-container > aside,
  .app-container > footer {
    display: none;
  }
  
  .app {
    overflow: hidden !important;
  }

  .text-muted {
    color: @gray-light !important;
  }
}