/**
   Component: datepicker.less
*/
 
[datepicker-popup] + ul,
[data-datepicker-popup] + ul {

  .btn-info > span {
    color: #fff;
  }

}


.datepicker {
  max-width: 100%;
  overflow-y: auto;
  table {
    width: 100%;
  }
}

.datepickerPositionRight {
  position: relative;
}
.datepickerPositionRight .dropdown-menu {
  left: auto !important;
  right: 0px;
}
