/**
   Component: bootstrap-reset.less
*/

//
// Use this file to reset simple bootstrap styles
// instead of modifying the core files
// Makes easy to update new framework versions
// --------------------------------------------------


// include custom variables
@import "bootstrap-variables";
// include bootstrap default mixins
@import "../../bootstrap/mixins";

// import font awesome code for reference to override
@import (less, reference) "../../../../vendor/font-awesome/css/font-awesome.min.css";


// treat ui link as normal links
[ui-sref],
[data-ui-sref] {
  cursor: pointer;
}

// required for Angular UI.Bootstrap
.nav-pills, 
.pagination, 
.carousel, 
.panel-title a { 
  cursor: pointer; 
}

[ng\:cloak], 
[ng-cloak], 
[data-ng-cloak], 
[x-ng-cloak], 
.ng-cloak, 
.x-ng-cloak {
  display: none !important;
}

*:focus {
  outline: 0 !important;
}

// Links
// ----------------------------------- 

a {
  outline: none !important;
  &:hover, &:focus {
    text-decoration: none;
  }
}
h1, h2, h3, h4, h5, h6 {
  > a.h-link {
    color: inherit;
  }
}

// List Groups
// ----------------------------------- 

.list-group {
  line-height: 1.3;
  .list-group-item {
    padding: 10px;
    .panel & {
      &:first-child {
        border-top: 0;
      }
    }
  }
}

.page-header {
  border-bottom-color: @gray-light;
}

.jumbotron {
  border: 1px solid @gray-lighter;
  padding: @jumbotron-padding;
  .shadow-z2;
}

.thumbnail {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
}

// Well
// ----------------------------------- 

.well {
  .shadow-z2;
}

// Nav Tabs
// ----------------------------------- 

.nav-tabs > li > a {
  font-weight: 400;
  color: @text-color;
  background-color: @gray-lighter;
  margin: 0;
  border: 1px solid @gray-light;
  border-radius: 0;
  padding: 8px 18px;
  cursor: pointer;

}

.nav-tabs > li.active > a {
  padding: 12px 22px;
  &, &:hover, &:focus {
    color: inherit;
    border-bottom-color: #fff;
  }
}

.nav-tabs > li {
  padding: 4px;
  &.active {
    padding: 0;
    + li {
      padding-left: 4px;
    }
  }
  &:first-child {
    padding-left: 0; 
  }
}

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: @gray-light;
  .nav-pills + & {
    border: 0;
    padding: 0;
  }
}

.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
  border-bottom: 0;
}

// Forms
// ----------------------------------- 

.form-control {
  padding-left: 18px;
  padding-right: 18px;
  box-shadow: 0 0 0 #000 !important;
}

.form-control,
.input-group-addon {
  border-color: #dbd9d9;
}

.input-group-addon {
  background-color: #f8f9fb;
}

.input-sm,
select.input-sm {
  height: 31px;
}

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px;
  &.last-child,
  &:last-child {
    border-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
}

// keep same appearance on ios devices
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="url"],
input[type="number"] {
  -webkit-appearance: none;
}  

// Tables
// ----------------------------------- 

.table {

  // Bottom align for column headings
  > thead > tr > th {
    border-bottom-width: 1px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-bottom-width: 1px;
  }

}

.table-bordered {
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 1px;
    }
  }
}

// Progress
// ----------------------------------- 

.progress {
  .box-shadow(0 0 0 #000);
  border-radius: @border-radius-small;
  border: 1px solid @progress-bg;
  background-color: #fff;
  .progress-bar {
    .box-shadow(0 0 0 #000);
  }
}

// Popover
// --------------------------------------------------

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid @gray-light;
  border-radius: @border-radius-small;
  .popover-title {
    border: 0;
  }
}

// DROPDOWN
.dropdown-menu {
  border-radius: @border-radius-small;
}

.dropdown-header {
  color: #a1a2a3;
}

// CAROUSEL

.carousel {
  .carousel-indicators {
    bottom: 0;
  }
  .carousel-control {
    &.left,
    &.right {
      background-image: none;
    }
    // Add support for font awesome
    em {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
    }
  }
}

// make glyphicon uses font awesome styles
.glyphicon:extend(.fa all) {
  &.glyphicon-chevron-right:extend(.fa-chevron-right all) {};
  &.glyphicon-chevron-left:extend(.fa-chevron-left all) {};
  &.glyphicon-chevron-up:extend(.fa-chevron-up all) {};
  &.glyphicon-chevron-down:extend(.fa-chevron-down all) {};
}

.panel > .table-responsive {
  max-width: 100%;
  overflow: auto;  
  // padding: 0 4px;
  // margin: 0 -4px;
}


.media-heading {
  a { text-decoration: none; }
}


// fix for ie9 (dropdwon no showing)
.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: default;
}