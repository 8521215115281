/**
   Component: notification.less
*/
.notification {

  .notification-item-list {
    position: relative;
  }

  .notification-item {
    .transition(~"color 0.6s, background-color 0.3s");

    .panel-heading {
      padding: 10px 10px;
    }

    .panel-title {
      font-size: 16px;
    }

    .notification-title {
      padding: 5px 0;
    }

    .notification-content {
      padding: 5px 0;
    }

    .notification-time {
      //margin-left: 200px;
      padding: 5px 20px;
    }

    &.ng-enter {
      .animation(fadeInLeft 1s);
    }

    &.ng-leave {
      position: absolute;
      left: 0; right: 0;
      .animation(fadeOutRightBig .5s);
      .animation-fill-mode(both);
    }

    .notification-down {
      display: inline;
      cursor: pointer;
    }
  }

  textarea {
    resize: none;
    max-width: 100%;
    min-width: 100%;
  }

  .panel-group {
    > .panel {
      border: 1px;
      margin: 0;
      .panel-body {
        border-bottom: 1px solid @gray-lighter;
        //.make-bg('gray-light', @text-color, #919293, @gray, #fff);
      }
    }
  }
}