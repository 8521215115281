.i-margin-bottom-20 {
    margin-bottom: 20px;
}

.i-margin-bottom-5 {
    margin-bottom: 5px;
}

li.i-splitter {
    margin-left: 10px !important;
    margin-right: 10px;
    width: 1px;
    height: 37px;
    background-color: #cccccc;
}

.i-editable-cell:hover {
    background-color: rgba(76,175,80, 0.2);
    .glyphicon-calendar:before {
        content: "\f073";
    }
}

.i-ui-select input{
    width: 100% !important;
}

.i-notification-nav {
    .nav {
        margin-top: 10px;
    }
    
    .nav-pills > li.active > a, 
    .nav-pills > li.active > a:hover, 
    .nav-pills > li.active > a:focus {
        background-color: #ffffff;
        color: #000000;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        font-weight: bold;
    }
}

#loanProgress .modal-dialog {
    width: 900px;
    hr {
        border-top: 2px solid #e5e5e5;
    }
    input[type='text'] {
        height: 39px
    }
}

.i-cover-letter {
    textarea {
        resize: none;
    }
}

.printable-only {
    display: none;
}

.i-printable-only-tr {
    display: none;
}

.table-responsive {
    position: relative;
}

.i-chart-legend {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.i-col-name {
    width: 10% !important;
}

.i-col-month {
    width: 7% !important;
}

@media print
{
    @page { 
        margin: 0;
    }

    .checkbox {
        margin: 0px;
    }


    .form-control, 
    #loanProgress .modal-dialog input[type='text'] {
        height: 20px;
        padding: 0;
    }

    .btn {
        padding: 0;
    }

    .printable-only {
        overflow-y: visible;
        height: 100%;
        width: 100%;
        display: block; 
    }

    .i-printable-only-tr {
        display: table-row;
    }

    .non-printable { 
        display: none; 
    }

    .app-content { 
        display: none; 
    }

    .i-cover-letter {
        textarea {
            resize: none;
        }
        
        td {
            padding: 3px 8px !important;
        }
    }

    .i-cover-letter-info {
        border: none;
        td {
            border: none !important;
            padding: 2px 0 !important;
        }
    }
}
