.i-column-header-name {
    text-decoration: underline;
    cursor: pointer;
}

.dropdown-menu.i-column-header-filter {
    display: block;
    top: 40px;
    left: auto;
}

.i-column-header-filter-form {
    padding: 5px;
    select {
        display: block;
        margin-bottom: 5px;
        height: 20px;
        line-height: 20px;
        width: 100%;
    }

    input[type='submit'] {
        display: none
    }
}

.i-column-header-has-filter {
    background-color: #3d3d3d;
}