/**
   Component: form-wizard.less
*/

@wizard-primary-color:        @brand-primary;
@wizard-steps-bg:             @jumbotron-bg;
@wizard-steps-bg-active:      @wizard-primary-color;

@wizard-heading-color:        @text-color;
@wizard-subheading-color:     @text-muted;


.steps-bar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step; // CSS counters to number the steps
  display: table;
  table-layout: fixed;
  width: 100%;
  
  > li {
    display: table-cell;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 10px;
    position: relative;
    text-align: center;
    
    > span {
      cursor: pointer;
    }
    
    &:before {
      content: counter(step);
      position: relative;
      counter-increment: step;
      width: 30px;
      line-height: 30px;
      display: block;
      font-size: 14px;
      color: #333;
      background: white;
      border-radius: 30px;
      margin: 0 auto 5px auto;
      z-index: 2;
    }
    
    // progressbar connectors
    &:after {
      content: '';
      width: 0;
      height: 2px;
      background: white;
      position: absolute;
      left: -50%;
      top: 14px;
      z-index: 1; /*put it behind the numbers*/
      .transition(width .3s ease-out);
    }
    
    // connector not needed before the first step
    &:first-child:after {
      content: none; 
    }
    
    /* paint the steps progress */
    &.active:before,  
    &.active:after {
      background: @brand-primary;
      color: white;
    }
    &.active:after {
      width: 100%;
    }
  }
}