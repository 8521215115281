/**
   Component: tasks.less
*/
.task {

  .task-item-list {
    position: relative;
  }

  .task-item {
    .transition(~"color 0.6s, background-color 0.3s");
    
    .panel-heading {
      padding: 20px 10px;
    }

    &.ng-enter {
      .animation(fadeInLeft 1s);
    }
    
    &.ng-leave {
      position: absolute;
      left: 0; right: 0;
      .animation(fadeOutRightBig .5s);
      .animation-fill-mode(both);
    }
    
    .task-edit {
      display: none;
      cursor: pointer;
    }
    &:hover .task-edit {
      display: inline;
    }
    &.task-complete .task-edit {
      display: none;
    }

  }

  textarea {
    resize: none;
    max-width: 100%;
    min-width: 100%;
  }

  .task-complete {
    background: @gray-lighter;
    opacity: .6;
    color: @gray-darker;
    
    .task-title {
      text-decoration: line-through;
    }
  }

  .panel-group {
    > .panel {
      border: 1px;
      margin: 0;
      .panel-body {
        border-bottom: 1px solid @gray-lighter;
      }
    }
  }
}